<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2590_9172)">
            <path d="M26.4563 15.1418H3.82887M3.82887 15.1418L13.0213 5.94945M3.82887 15.1418L13.0213 24.3342"
                  stroke="black" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_2590_9172">
                <rect width="20" height="20" fill="white"
                      transform="matrix(-0.707107 0.707107 0.707107 0.707107 15.1426 1)"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "CalendarScheduleBackIcon"
}
</script>

<style>

</style>
