<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3901 4.88794H10.9341L5.41413 10.3359L10.9341 15.7839H14.3901L8.84614 10.3359L14.3901 4.88794Z"
              fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "SmallCalendarLeftIcon"
}
</script>

<style>

</style>
