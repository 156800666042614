<template>
  <main-layout>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="w-max-content m-0">{{ $t("headerCalendarBtn") }}</h1>
      <router-link
        to="/working-hours"
        class="d-flex align-items-center cursor-pointer"
      >
        <working-hours-icon />
        <div class="expertCalendarPage__workingHours">
          {{ $t("workingHours") }}
        </div>
      </router-link>
    </div>
    <div class="d-flex align-items-center mb-20 mt-20">
      <div class="cursor-pointer" @click="$router.back()">
        <calendar-schedule-back-icon />
      </div>
      <div class="calendarSchedule__date">
        <span class="weight-400"
          ><data-formatted date-format="EEEE, d MMMM" :data="currentDay"
        /></span>
        <span><data-formatted date-format=" yyyy" :data="currentDay" /></span>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="calendarSchedule__appointmentTitle">
          {{ $t("schedulesAppointment") }}
          <div class="calendarSchedule__scroll">
            <div v-for="appointment in appointments">
              <appointments :appointment="appointment" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div>
          <div class="calendarSchedule__calendarWrapper">
            <div class="calendarSchedule__calendarHeaderMenu">
              <div @click="previouslyDay()" class="cursor-pointer">
                <small-calendar-left-icon />
              </div>
              <div @click="setToday()" class="calendarSchedule__calendarToday">
                {{ $t("today") }}
              </div>
              <div @click="nextDay()" class="cursor-pointer">
                <small-calendar-right-icon />
              </div>
            </div>
            <div class="calendarSchedule__calendarContainer">
              <div class="d-flex">
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("mon") }}
                </div>
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("tue") }}
                </div>
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("wed") }}
                </div>
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("thu") }}
                </div>
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("fri") }}
                </div>
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("sat") }}
                </div>
                <div class="calendarSchedule__calendarHeader">
                  {{ $t("sun") }}
                </div>
              </div>
              <div
                v-for="week in calendar"
                class="d-flex justify-content-between"
              >
                <div
                  v-for="day in week"
                  class="calendarSchedule__calendarCell"
                  @click="changeDay(day)"
                  :class="{
                    'calendarSchedule__calendarCell-today': isToday(day),
                    'calendarSchedule__calendarCell-anotherMonth':
                      isThisMonth(day),
                    'calendarSchedule__calendarCell-selectDay':
                      isSelectDay(day),
                  }"
                >
                  <data-formatted date-format="dd" :data="day" />
                </div>
              </div>
            </div>
          </div>
          <div class="calendarSchedule__working">{{ $t("workingHours") }}:</div>
          <div class="calendarPage__scheduleScroll">
            <div v-for="(day, i) in schedules" class="mb-10 w-max-content">
              <div class="calendarPage__scheduleRangeTitle">
                {{ toWords(i + 1) }} range
              </div>
              <div class="d-flex align-items-center w-max-content">
                <div class="calendarPage__dayCalendarRowTimeWrapper w-100">
                  <input
                    v-model="day.start_time"
                    @blur="addScheduleRange(day)"
                    type="time"
                  />
                  to
                  <input
                    v-model="day.end_time"
                    @blur="addScheduleRange(day)"
                    type="time"
                  />
                </div>
                <div class="cursor-pointer" @click="removeRangeElement(day)">
                  <calendar-day-remove-range-icon />
                </div>
                <div
                  v-if="schedules.length === i + 1"
                  @click="addRangeElement()"
                  class="ml-10"
                >
                  <calendar-day-add-range-icon />
                </div>
              </div>
            </div>
            <div
              v-if="schedules.length === 0"
              class="calendarPage__dayCalendarRowEditBtn calendarPage__scheduleAdd"
            >
              <div @click="addRangeElement()">
                <calendar-day-add-range-icon />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import WorkingHoursIcon from "@/components/UI/icons/WorkingHoursIcon";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import { mapGetters } from "vuex";
import CalendarScheduleBackIcon from "@/components/UI/icons/arrows/CalendarScheduleBackIcon";
import Appointments from "@/components/elements/appointment/Appointments";
import Datepicker from "@vuepic/vue-datepicker";
import {
  add,
  addDays,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfMonth,
  format,
  isSameDay,
  isSunday,
  isThisMonth,
  isToday,
  set,
  startOfMonth,
} from "date-fns";
import SmallCalendarLeftIcon from "@/components/UI/icons/arrows/SmallCalendarLeftIcon";
import SmallCalendarRightIcon from "@/components/UI/icons/arrows/SmallCalendarRightIcon";
import DayCalendarRow from "@/components/elements/calendar/DayCalendarRow";
import CalendarDayAddRangeIcon from "@/components/UI/icons/addRemoveIcons/CalendarDayAddRangeIcon";
import CalendarDayRemoveRangeIcon from "@/components/UI/icons/addRemoveIcons/CalendarDayRemoveRangeIcon";
import converter from "number-to-words";
import { useToast } from "vue-toastification";

export default {
  name: "ExpertSchedulesDayPage",
  components: {
    CalendarDayRemoveRangeIcon,
    CalendarDayAddRangeIcon,
    DayCalendarRow,
    SmallCalendarRightIcon,
    SmallCalendarLeftIcon,
    Appointments,
    CalendarScheduleBackIcon,
    DataFormatted,
    Datepicker,
    WorkingHoursIcon,
    MainLayout,
  },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      appointments: {},
      schedules: {},
      selectDay: "",
      calendar: {},
    };
  },
  mounted() {
    this.response();
  },
  methods: {
    async response() {
      let start = startOfMonth(new Date(this.currentDay));
      let end = endOfMonth(new Date(this.currentDay));
      this.appointments = await this.getAppointments();
      this.schedules = await this.getSchedules();
      this.calendar = await this.setDataInterval(start, end);
    },
    async getAppointments() {
      let date =
        format(new Date(this.currentDay), "yyyy-MM-dd") + "T00:00:00.000Z";
      return this.$http
        .get(`appointments/date/${date}`, {
          params: {
            canceled: true,
          },
        })
        .then(({ data }) => {
          return data.body;
        });
    },
    async getSchedules() {
      let date =
        format(new Date(this.currentDay), "yyyy-MM-dd") + "T00:00:00.000Z";
      return this.$http.get(`schedules/date/${date}`).then(({ data }) => {
        let dataValues = [];
        for (let i in data.body) {
          dataValues.push({
            end_time: format(new Date(data.body[i].end_time), "HH:mm"),
            id: data.body[i].id,
            start_time: format(new Date(data.body[i].start_time), "HH:mm"),
          });
        }
        return dataValues;
      });
    },
    async setDataInterval(start, end) {
      let days = [];
      let month = [];
      let weeks = eachWeekOfInterval(
        {
          start: start,
          end: end,
        },
        { weekStartsOn: 1 }
      );
      weeks.forEach((week) => {
        days.push(
          eachDayOfInterval({
            start: new Date(week),
            end: add(new Date(week), { days: 6 }),
          })
        );
      });
      days = JSON.parse(JSON.stringify(days));
      days.forEach((week) => {
        let days = [];
        week.forEach((day) => {
          days.push(day);
        });
        month.push(days);
      });
      return month;
    },
    addScheduleRange(value) {
      if (
        value.start_time.includes("AM") ||
        value.start_time.includes("PM") ||
        value.end_time.includes("AM") ||
        value.end_time.includes("PM")
      ) {
        value.start_time = this.convertTime12To24(value.start_time);
        value.end_time = this.convertTime12To24(value.end_time);
      }
      if (+value.start_time.slice(0, 2) < 8) {
        this.toast.warning(this.$t("SchedulesIntervalToast"));
        return;
      } else if (+value.end_time.slice(0, 2) > 20) {
        this.toast.warning(this.$t("SchedulesIntervalToast"));
        return;
      } else if (
        +value.end_time.slice(3) !== 0 &&
        +value.end_time.slice(0, 2) >= 20
      ) {
        this.toast.warning(this.$t("SchedulesIntervalToast"));
        return;
      }
      if (value.id) {
        this.patchSchedule(value);
        return;
      }
      if (value.start_time.length > 0 && value.end_time.length > 0) {
        let currantDay = format(new Date(this.currentDay), "yyyy-MM-dd");
        let hours_start = Number(value.start_time.substring(0, 2));
        let minute_start = Number(value.start_time.substring(3));
        let hours_end = Number(value.end_time.substring(0, 2));
        let minute_end = Number(value.end_time.substring(3));
        let start_time = set(new Date(currantDay), {
          hours: hours_start,
          minutes: minute_start,
        });
        let end_time = set(new Date(currantDay), {
          hours: hours_end,
          minutes: minute_end,
        });
        this.$http
          .post("schedules", {
            start_time: start_time,
            end_time: end_time,
            date: currantDay,
          })
          .then(() => {
            this.toast.success(this.$t("successfulSaveData"));
            this.response();
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.toast.error(err.response.data.message);
            } else {
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    patchSchedule(range) {
      let today = format(new Date(this.currentDay), "yyyy-MM-dd");
      let hours_start = Number(range.start_time.substring(0, 2));
      let minute_start = Number(range.start_time.substring(3));
      let hours_end = Number(range.end_time.substring(0, 2));
      let minute_end = Number(range.end_time.substring(3));
      let start_time = set(new Date(this.currentDay), {
        hours: hours_start,
        minutes: minute_start,
      });
      let end_time = set(new Date(this.currentDay), {
        hours: hours_end,
        minutes: minute_end,
      });
      this.$http
        .patch(`schedules/${range.id}`, {
          start_time: start_time,
          end_time: end_time,
          date: today,
        })
        .then(() => {
          this.toast.success(this.$t("successfulSaveData"));
          this.response();
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    setToday() {
      this.$store.commit("SET_CALENDAR_DAY_SELECT", new Date().toISOString());
      this.response();
    },
    nextDay() {
      let day = addDays(new Date(this.currentDay), 1);
      this.$store.commit("SET_CALENDAR_DAY_SELECT", day.toISOString());
      this.response();
    },
    previouslyDay() {
      let day = addDays(new Date(this.currentDay), -1);
      this.$store.commit("SET_CALENDAR_DAY_SELECT", day.toISOString());
      this.response();
    },
    changeDay(day) {
      if (isSunday(new Date(day))) {
        this.toast.warning(this.$t("WorkingInterval"));
        return;
      }
      this.$store.commit("SET_CALENDAR_DAY_SELECT", day);
      this.response();
    },
    addRangeElement() {
      this.schedules.push({ start_time: "", end_time: "" });
    },
    removeRangeElement(value) {
      if (value.id) {
        this.$http.delete(`schedules/${value.id}`).then(() => {
          this.toast.success(this.$t("successfulSaveData"));
          this.schedules = this.schedules.filter(
            (item) => item.id !== value.id
          );
        });
      } else {
        this.schedules.pop();
      }
    },
    isToday(day) {
      return isToday(new Date(day));
    },
    isThisMonth(day) {
      return !isThisMonth(new Date(day));
    },
    isSelectDay(day) {
      return isSameDay(new Date(day), new Date(this.currentDay));
    },
    toWords(value) {
      if (!value) return "";
      return converter.toWordsOrdinal(value);
    },
    convertTime12To24(time) {
      let hours = Number(time.match(/^(\d+)/)[1]);
      let minutes = Number(time.match(/:(\d+)/)[1]);
      let AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === "PM" && hours < 12) hours = hours + 12;
      if (AMPM === "AM" && hours === 12) hours = hours - 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      return sHours + ":" + sMinutes;
    },
  },
  computed: {
    ...mapGetters({
      currentDay: "CALENDAR_DAY_SELECT",
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
