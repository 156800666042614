<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.60986 4.88794H9.06586L14.5859 10.3359L9.06586 15.7839H5.60986L11.1539 10.3359L5.60986 4.88794Z"
              fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "SmallCalendarRightIcon"
}
</script>

<style>

</style>
